
            @import "@/assets/sass/vars.portal.scss";
          






































































































































































































































.totaler {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid $gray-400;
}
